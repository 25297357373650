<template>
  <div class="zdd-share">
    <div class="zdd-share-card">
      <div class="zdd-share-card-title">
        <!-- 邀请 -->
        <template v-if="isInvite && !isComUser">
          <div class="zdd-share-card-title-main">邀请您使用职袋袋</div>
          <div class="zdd-share-card-title-sub">职业档案袋，守护职场信用~</div>
        </template>

        <template v-if="isInvite && isComUser">
          <div class="zdd-share-card-title-main">
            邀请身边企业，一起守护职场信用
          </div>
          <div class="zdd-share-card-title-sub">
            每邀请1家企业使用，赠送双方各100元充值额度
          </div>
        </template>
        <!-- 邀请 -->

        <!-- 分享授权 -->
        <template v-else-if="isShareProfile">
          <div class="zdd-share-card-title-main">
            我的职场档案，24小时有效，请查收
          </div>
        </template>
        <!-- 分享授权 -->

        <template v-if="isShareJoinSign">
          <div class="zdd-share-card-title-main">
            我在职袋袋发起了一个证书，赶快接收吧
          </div>
          <div class="zdd-share-card-title-sub">
            <div class="zdd-share-card-title-sub-item">
              发起方：{{
                businessDetail.launcher || businessDetail.launchUserName
              }}
            </div>
            <div class="zdd-share-card-title-sub-item">
              证书类型：{{ businessDetail.businessTitle }}
            </div>
            <div class="zdd-share-card-title-sub-item">
              截止时间：{{ (businessDetail.byTime * 1000) | datetimeFormat }}
            </div>
          </div>
        </template>
      </div>
      <div class="zdd-share-card-main">
        <div class="zdd-share-card-main-content" v-if="isShareProfile">
          <div class="zdd-share-card-main-content-item">
            <span class="zdd-share-card-main-content-item__label"
              >发送人：</span
            >
            <span class="zdd-share-card-main-content-item__value">{{
              token ? creditTokenInfo.userFullName : userInfo.name
            }}</span>
          </div>
          <div class="zdd-share-card-main-content-item">
            <span class="zdd-share-card-main-content-item__label"
              >发送内容：</span
            >
            <span class="zdd-share-card-main-content-item__value"
              >职场档案</span
            >
          </div>
          <div class="zdd-share-card-main-content-item">
            <span class="zdd-share-card-main-content-item__label"
              >截止时间：</span
            >
            <span class="zdd-share-card-main-content-item__value">{{
              deadlineTime
            }}</span>
          </div>
        </div>
        <div class="zdd-share-card-main-qrcode-container">
          <van-image :src="qrcodeDataUrl" class="qrcode">
            <template v-slot:loading>
              <van-loading type="spinner" size="20" />
            </template>
          </van-image>
          <div class="description-main">长按保存二维码</div>
          <div class="description-sub">职袋袋为您提供服务</div>
          <div
            class="copy-link-button"
            v-clipboard:copy="shareUrl"
            v-clipboard:success="onCopy"
          >
            复制链接
          </div>
        </div>
      </div>
    </div>
    <div class="zdd-share-other">
      <!--      <div class="zdd-share-other-split">
        <div class="zdd-share-other-split-text">还可以通过以下方式邀请</div>
      </div>
      <div class="zdd-share-other-button-group">
        &lt;!&ndash;        <div class="zdd-share-other-button-group-item">
          <div class="button-icon">
            <SvgIcon type="zdd_share_qrcode" class="icon" />
          </div>
          <div class="button-text">保存二维码</div>
        </div>&ndash;&gt;
        <div
          class="zdd-share-other-button-group-item"
          v-clipboard:copy="shareUrl"
          v-clipboard:success="onCopy"
        >
          <div class="button-icon">
            <SvgIcon type="zdd_share_link" class="icon" />
          </div>
          <div class="button-text">复制链接</div>
        </div>
      </div>-->
      <div class="zdd-share-other-back">
        <van-button
          class="back-button"
          v-if="isInvite"
          @click="handleBack($event, true)"
          >已邀请，返回首页</van-button
        >
        <van-button
          class="back-button"
          v-else-if="isShareProfile"
          @click="handleBack"
          >返回</van-button
        >
      </div>
    </div>

    <div class="zdd-share-guide">
      <SvgIcon type="zdd_share_guide" class="zdd-share-guide-icon" />
      <div class="zdd-share-guide-text">
        通过微信<br />
        分享
      </div>
    </div>
  </div>
</template>

<script>
  import { SHARE_TYPE } from '@/common/zddEnum'
  import QrCode from 'qrcode'
  import archivesApi from '@/api/user/archives'
  import authApi from '@/api/creadit'
  import contractBusinessApi from '@/api/contractBusiness'
  import { datetimeFormat } from '@/utils/datetimeFormat'

  export default {
    name: 'ZddShare',
    data() {
      return {
        SHARE_TYPE: Object.freeze(SHARE_TYPE),
        businessId: undefined,
        type: SHARE_TYPE.INVITE,
        qrcodeDataUrl: undefined,
        shareUrl: undefined,
        deadlineTime: undefined,
        token: undefined,
        creditTokenInfo: {
          userFullName: undefined,
        },
        partnerId: undefined,

        businessDetail: {},
      }
    },
    computed: {
      userInfo() {
        return this.$store.getters.getUserInfo
      },
      systemInfo() {
        return this.$store.state.system
      },
      isInvite() {
        return this.type === SHARE_TYPE.INVITE
      },
      isShareProfile() {
        return this.type === SHARE_TYPE.SHARE_PROFILE
      },
      isShareJoinSign() {
        return this.type === SHARE_TYPE.JOIN_SIGN
      },
      creditInfoIsCom() {
        return !!this.creditTokenInfo.comId
      },
      isComUser() {
        return this.$store.getters.isComUser
      },
    },
    created() {
      const { businessId, type, token, partnerId } = this.$route.query
      this.businessId = businessId
      this.token = token
      this.type = parseInt(type, 10)
      this.partnerId = partnerId

      this.init()
    },
    mounted() {},
    methods: {
      async init() {
        let loadingInstance
        try {
          loadingInstance = this.$toast.loading({
            message: '加载中',
            loadingType: 'spinner',
          })
          if (this.token) {
            await this.getCreditTokenInfo()
          }
          await this.generateShareLink()
          this.generateQrCode(this.shareUrl)
        } catch (error) {
          console.error(error)
        } finally {
          if (loadingInstance) {
            loadingInstance.close()
          }
        }
      },

      initWxShare(url) {
        window.wx.ready(() => {
          console.log('wx ready')
          window.wx.checkJsApi({
            jsApiList: [
              'updateAppMessageShareData',
              'onMenuShareAppMessage',
              'updateTimelineShareData',
            ],
            success: res => {
              console.log('check js api', res)
            },
          })
          let title = `邀请你使用「职袋袋」，一起守护职场信用吧~`
          let desc = `在职袋袋查档、建档、发高光证书，快速识别人才、激发团队活力`
          if (this.isShareProfile) {
            // 自己授权分享
            title = `我的职场档案，24小时有效，请查收`
            desc = `发送人：${
              this.token
                ? this.creditTokenInfo.userFullName
                : this.userInfo.name
            }\n发送内容：职场档案\n查看截止时间：${this.deadlineTime}`
          } else if (this.isShareJoinSign) {
            // 发起后分享加入链接
            title = `我在职袋袋发起了一个证书，赶快接收吧`
            desc = `发起方：${this.businessDetail.launcher ||
              this.businessDetail.launchUserName}\n证书类型：${
              this.businessDetail.businessTitle
            }\n截止时间:${datetimeFormat(this.businessDetail.byTime * 1000)}`
          }
          // 需在用户可能点击分享按钮前就先调用
          window.wx.updateAppMessageShareData({
            title, // 分享标题
            desc, // 分享描述
            link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: this.systemInfo.logo2,
            // 'https://h5.ecloudsign.com/img/icons/android-chrome-192x192.png', // 分享图标
            success() {
              // 设置成功
              console.log('分享给朋友')
            },
            cancel() {
              console.log('share cancel')
            },
            fail(res) {
              console.log('share fail', res)
            },
          })

          window.wx.updateTimelineShareData({
            title, // 分享标题
            desc, // 分享描述
            link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: this.systemInfo.logo2,
            // 'https://h5.ecloudsign.com/img/icons/android-chrome-192x192.png', // 分享图标
            success() {
              // 设置成功
              console.log('分享朋友圈')
            },
            cancel() {
              console.log('share cancel')
            },
            fail(res) {
              console.log('share fail', res)
            },
          })

          // 即将废弃
          window.wx.onMenuShareAppMessage({
            title, // 分享标题
            desc, // 分享描述
            link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: this.systemInfo.logo2,
            type: 'link', // 分享类型,music、video或link，不填默认为link
            dataUrl: '', // 如果type是music或video，则要提供数据链接，默认为空
            success() {
              // 用户点击了分享后执行的回调函数
              console.log('share success 废弃接口')
            },
            cancel() {
              console.log('share cancel 废弃接口')
            },
            fail(res) {
              console.log('share fail 废弃接口')
            },
          })
        })
      },

      // 获取授权码
      async getEmpowerCode() {
        try {
          const model = {
            userId: this.token
              ? this.creditTokenInfo.userId
              : this.userInfo.userId,
          }
          console.log(model)
          const { code, deadlineTime } = await archivesApi.getEmpowerCode(model)
          this.deadlineTime = deadlineTime
          return code
        } catch (error) {
          console.error(error)
        }
      },

      async generateShareLink() {
        const { hostH5 } = this.systemInfo
        if (this.isInvite) {
          const isComUser = this.token ? this.creditInfoIsCom : this.isComUser
          if (isComUser) {
            // 企业身份 邀请企业
            this.shareUrl = `${hostH5}zdd/invitepublic?comId=${
              this.token ? this.creditTokenInfo.comId : this.userInfo.comId
            }`
          } else {
            // 个人身份 邀请企业
            this.shareUrl = `${hostH5}zdd/invitepublic?userId=${
              this.token ? this.creditTokenInfo.userId : this.userInfo.userId
            }`
          }
          console.log(this.shareUrl)
        } else if (this.isShareProfile) {
          const code = await this.getEmpowerCode()
          this.shareUrl = `${hostH5}login?code=${code}`
        } else if (this.isShareJoinSign) {
          const success = await this.getBusinessDetail()
          if (!success) return
          // 分享加入签署
          this.shareUrl = `${hostH5}addSign?partnerId=${this.partnerId}`
        }

        this.initWxShare(this.shareUrl)
      },

      async getCreditTokenInfo() {
        try {
          const model = {
            creditToken: this.token,
          }
          this.creditTokenInfo = await authApi.getCreditTokenInfo(model)
        } catch (error) {
          console.error(error)
        }
      },

      onCopy() {
        this.$toast.success('复制链接成功')
      },

      generateQrCode(url) {
        this.$nextTick(() => {
          QrCode.toDataURL(url, {}, (err, res) => {
            if (err) {
              console.error(err)
            } else {
              this.qrcodeDataUrl = res
            }
          })
        })
      },

      handleBack(e, backHome = false) {
        if (backHome) {
          this.$router.replace({
            name: 'ZddHome',
          })
        } else {
          this.$router.back()
        }
      },

      async getBusinessDetail() {
        try {
          const model = {
            businessId: this.businessId,
          }
          this.businessDetail = await contractBusinessApi.detail(model)
          return true
        } catch (error) {
          console.error(error)
          return false
        }
      },
    },
  }
</script>

<style scoped lang="less">
  .zdd-share {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    background: rgba(0, 0, 0, 0.65);
    position: relative;
    .zdd-share-card {
      width: 271px;
      background: @WHITE;
      border-radius: 10px;
      position: absolute;
      top: 65px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 9;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      .zdd-share-card-title {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 18px 15px 14px;
        background: var(--main-color);
        border-radius: 10px 10px 0 0;
        color: #ffffff;
        &-main {
          font-size: 14px;
          font-weight: 600;
          line-height: 16px;
        }
        &-sub {
          margin-top: 6px;
          font-size: 12px;
          font-weight: 500;
        }
        .zdd-share-card-title-sub-item + .zdd-share-card-title-sub-item {
          margin-top: 6px;
        }
      }
      .zdd-share-card-main {
        flex: 1;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        padding: 15px;
        border: 1px solid #f1f2f5;
        border-top: none;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        &-content {
          display: flex;
          flex-direction: column;
          padding-bottom: 12px;
          border-bottom: 1px dashed var(--main-color);
          &-item {
            display: flex;
            font-size: 12px;
            line-height: 14px;
            &__label {
              color: #677283;
            }
            &__value {
              color: #111a34;
            }
          }
          .zdd-share-card-main-content-item
            + .zdd-share-card-main-content-item {
            margin-top: 8px;
          }
        }
        &-qrcode-container {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .qrcode {
            width: 128px;
            height: 128px;
          }
          .description-main {
            font-size: 14px;
            font-weight: 400;
            color: #000000;
            line-height: 20px;
          }
          .description-sub {
            margin-top: 3px;
            font-size: 12px;
            font-weight: 400;
            color: #a2a2a2;
            line-height: 17px;
          }
          .copy-link-button {
            margin-top: 24px;
            margin-bottom: 10px;
            font-size: 16px;
            color: var(--main-color);
          }
        }
      }
    }
    .zdd-share-other {
      width: 100%;
      height: 55%;
      position: absolute;
      bottom: 0;
      z-index: 8;
      &-split {
        margin-top: 121px;
        &-text {
          font-size: 12px;
          font-weight: 400;
          color: #677283;
          line-height: 17px;
          display: flex;
          justify-content: center;
          align-items: center;
          &:before {
            content: '';
            width: 41px;
            height: 1px;
            background: #e2e4ea;
            margin-right: 16px;
          }
          &:after {
            content: '';
            width: 41px;
            height: 1px;
            background: #e2e4ea;
            margin-left: 10px;
          }
        }
      }
      &-button-group {
        margin-top: 30px;
        display: flex;
        padding: 0 78px;
        justify-content: center;
        &-item {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .button-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 58px;
            height: 58px;
            background: #ffffff;
            border-radius: 50%;
            box-shadow: 0 0 4px 0 #eeeeee;
            .icon {
              font-size: 20px;
            }
          }
          .button-text {
            margin-top: 11px;
            font-size: 12px;
            font-weight: 400;
            color: #000000;
            line-height: 17px;
          }
        }
      }
      &-back {
        position: absolute;
        bottom: 0;
        width: 100%;
        display: flex;
        .back-button {
          width: 100%;
          height: 100%;
          padding-top: 14px;
          padding-bottom: 14px;
          border: none;
          border-radius: 0;
          .van-button__text {
            font-size: 16px;
            font-weight: 400;
            text-align: center;
            color: #111a34;
            line-height: 22px;
            height: 22px;
          }
        }
      }
    }
    &-guide {
      position: absolute;
      top: 4px;
      right: 7px;
      .zdd-share-guide-icon {
        font-size: 43px;
      }
      .zdd-share-guide-text {
        width: 43px;
        font-size: 10px;
        font-weight: 400;
        text-align: center;
        color: #ffffff;
        line-height: 12px;
        white-space: nowrap;
      }
    }
  }
</style>
